<template>
  <div class="user-portfolio">
    <div>
      <div class="user-portfolio-header">
        <img  class="avatar" :alt="userInfo.username" :src="userInfo.avatar_url">
        <div style="width:100%;display: flex; justify-content: space-between">
          <div style="margin-left: 25px;">
            <h1 class="title"><span>{{userInfo.username}}</span> kullanıcısının portfolyosu</h1>
            <p class="sub-title">{{userInfo.username}} kullanıcısının işlerini beğendiysen, iş ilanlarını incelemeni tavsiye ederiz. Belki de ihtiyacın olan o işi birlikte yaparsınız. 😉 <router-link :to="'/'+userInfo.username">[ilanlarına göz at]</router-link></p>
          </div>
          <div @click="clickedShareButton"  class="share-area">
            <p class="text">Paylaş</p>
            <social-sharing :url="url" inline-template>
              <div class="user-socialmedia-icons">
                <network network="facebook" style="cursor: pointer;">
                  <img  style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/facebook.png"/>
                </network>

                <network  network="twitter" style="cursor: pointer; margin-left: 20px;">
                  <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/twitter.png"/>
                </network>

                <network network="linkedin" style="cursor: pointer; margin-left: 20px;">
                  <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/linkedin.png"/>
                </network>
              </div>
            </social-sharing>
            <div @click="copyToClipboard()" style="margin-left: 20px;">
              <img  class="share-icon" style="width: 26px; height: 26px; border-radius: 50%; cursor: pointer" src="https://gcdn.bionluk.com/site/cicons/png/link.png"/>
            </div>

          </div>
        </div>
      </div>

      <div class="tags" v-if="tags.length <= 6">
        <p v-for="(tag, ti) in tags" @click="selectTag(tag)" :class="tag.value === selectedTag ? 'tag-selected' : 'tag'" >
          {{tag.key}}
        </p>
      </div>
      <div class="tags" v-if="tags.length > 6">
        <p v-for="(tag, ti) in tags" v-if="ti < 5" @click="selectTag(tag)" :class="tag.value === selectedTag ? 'tag-selected' : 'tag'" >
          {{tag.key}}
        </p>
        <div @click="showOtherTagsContainer" :class="otherTagKey ? 'tag-selected' : 'tag'">
          {{otherTagKey ? otherTagKey : 'Diğer Kategoriler'}}
          <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-down.svg" onload="SVGInject(this)" class="arrow-icon">
          <div id="other-tags-container" class="ses-gender-container" tabindex="0" :style="showOtherTags ? 'visibility: visible;' : 'visibility:hidden;'" @focusout="showOtherTags = false;" >
            <div style=" padding: 20px ;display: flex; flex-direction: column; justify-content: center">
              <div class="ses-gender-radio-item" @click="selectOtherTag(tag)"  v-for="(tag, tii) in tags" v-if="tii >= 5" :style="tii > 5 ? 'margin-top: 20px' : ''">
                <div :class="tag.value === selectedTag ? 'ses-gender-radio-full' : 'ses-gender-radio-empty'"></div>
                <p :class="tag.value === selectedTag ? 'ses-gender-radio-label-active' : 'ses-gender-radio-label'">{{tag.key}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style=" margin-left: -30px">
        <div class="portfolio-body">
          <div v-for="portfolio in portfolios" class="portfolio-box">
            <div class="head-section">
              <p class="title">
                <a :href="specifyPostUrl(portfolio.uuid)" @click.stop.prevent="preventClicking($event)" @click="showPortfolioDetailModal(portfolio)">
                  {{portfolio.name}}
                </a>
               </p>
              <p class="sub-title" v-if="portfolio.rating_user.id"><router-link :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yapıldı.</p>
              <p v-else class="sub-title">{{portfolio.category_sub_name}}</p>
            </div>
            <a :href="specifyPostUrl(portfolio.uuid)" @click.stop.prevent="preventClicking($event)" class="portfolio-img" @click="showPortfolioDetailModal(portfolio)">
              <div v-if="portfolio.file_type === 'audio'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <div v-if="portfolio.file_type === 'video'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <img :src="portfolio.image_url_small" :alt="portfolio.name">
            </a>
            <div class="foot-section">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon"/>
              <span>{{portfolio.fav_count}}</span>
              <img src="https://gcdn.bionluk.com/site/cicons/ic-messages.svg" onload="SVGInject(this)" class="like-icon"/>
              <span>{{portfolio.comment_count}}</span>
            </div>
          </div>
        </div>
      </div>


      <div v-if="loader" style="margin-left: auto; margin-right: auto;">
        <div style="display: flex;flex-wrap: wrap">
          <div v-for="x in 8" class="card-container">

            <div class="card-detail">

              <div class="card-detail-user-container ">
                <div class="card-detail-user">
                  <div class="card-detail-user-image card"></div>
                </div>
                <div style="display: flex; flex-direction: column">
                  <div class="card-detail-user-name card">
                  </div>
                  <div class="card-detail-user-name card" style="height: 10px; margin-top: 5px; width: 120px">
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-image-container card">
            </div>

            <div class="card-like-currency">
              <div class="card-like-icon card"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="see-more-div" @click="retrievePortfolios(offset, limit, true)"
           v-if="!seemoreLoader && seemorediv && !loader">
        <p style="color: #2d3640; font-size: 18px; font-weight: 500"><span style="font-size: 24px; line-height: 1">+</span> Daha fazla göster</p>
      </div>

    </div>

  </div>
</template>

<script>

  export default {
    name: "src-pages-body-portfolio-v1",
    data() {
      return {
        selectedTag:null,
        tags:[],
        username: null,
        offset: 0,
        limit: 16,
        seemorediv: false,
        loader: true,
        seemoreLoader: false,
        portfolios: [],
        userInfo: {avatar_url:null},
        url: window.location.href,

        otherTagKey:null,
        showOtherTags:false
      }
    },

    methods: {
      selectOtherTag(tag){
        this.otherTagKey = tag.key;
        this.selectedTag = tag.key;
        this.selectTag(tag, true);
      },
      showOtherTagsContainer(){
        if (this.tags.length) {
          if (!this.showOtherTags) {
            this.showOtherTags = true
            this.$nextTick(() => {
              document.getElementById("other-tags-container").focus()
            })
          } else {
            this.showOtherTags = false
          }
        }
      },

      selectTag(tag, isOtherTag = false){
        if(this.loader) return false;
        this.portfolios = [];
        this.offset = 0;
        if(tag.value === this.selectedTag){
          this.selectedTag = null;
          this.retrievePortfolios(this.offset, this.limit, false);
        } else {
          this.selectedTag = tag.value
          this.retrievePortfolios(this.offset, this.limit, false);
        }

        if(!isOtherTag){
          this.otherTagKey = null;
        }
      },
      copyToClipboard(){
        const el = document.createElement('textarea');
        el.value = 'https://bionluk.com/portfolyo/'+this.userInfo.username;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.global.infoToast({description: 'Portfolyo linki kopyalandı.'});
      },

	    clickedShareButton(){
		    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'portfolio share buttons'});
	    },

      async retrievePortfolios(offset, limit, comingSeeMoreButton, tag=null) {

        this.loader = true;
        if(!this.userInfo.avatar_url){
          let basicUser = await this.api.seller.basicProfile(this.username);
          this.userInfo = basicUser.data.data.user;
          this.tags = basicUser.data.data.tags;
        }

        if (comingSeeMoreButton) {
          this.seemoreLoader = true;
        }
        this.$store.state.indexHTMLTitle = this.username + ' portfolyo sayfası | Bionluk';
        this.$store.state.indexHTMLMetaDescription = this.username + ' kullanıcına ait iş örnekleri';
        let postedTag = this.selectedTag ? this.selectedTag : '';
        this.api.seller.listPortfolios(this.username, this.Constants.PORTFOLIO_STATUSES.ACTIVE, limit, offset, null, postedTag)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.userInfo = result.data.portfolio_user;
              this.Helper.trackEvents.pageView(this.Helper.portfolioOpennedProps(result.data.portfolio_user.userName, result.data.portfolio_user.userID, result.data.portfolio_user.userUUID, this.$route.query.gig_id || ''));
              this.seemorediv = result.data.seemorediv;

              this.portfolios = this.portfolios.concat(result.data.portfolios);
              this.offset += limit;
              this.seemorediv = result.data.seemorediv;
              if(this.portfolios.length > 0)  this.userInfo =  result.data.portfolios[0].user;


              // if (this.$store.state.showCase.byModal && this.$store.state.showCase.to === this.$store.state.route.from.fullPath) {
              //   this.$nextTick(() => {
              //     this.showPortfolioDetailModal(this.$store.state.showCase.data);
              //   });
              // }
            } else {
	            this.$store.commit(this.types.SET_COMPONENT_MAP, this.$store.state.routerMap["/*"])

            }
            this.loader = false;
            this.seemoreLoader = false;

            this.$nextTick(() => {
              window.scrollTo(0,this.$store.state.portfolioScrollTopPosition);
            });
          })
          .catch(err => {
            this.seemoreLoader = false;
            this.loader = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      specifyPostUrl(ID) {
        return /freelancer-vitrin/ + ID;
      },

      preventClicking(event) {
        event.returnValue = false;
        return false;
      },

      showPortfolioDetailModal(portfolio) {
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: portfolio.uuid, allItems:this.portfolios}});
      },
    },

    watch: {
      "$store.state.routerParams": function (newValue, oldValue) {
        this.username = newValue[0];
       // this.retrievePortfolios(this.offset, this.limit);
      },
      "$store.state.windowScrollTop": function (newValue, oldValue) {
        this.$store.state.portfolioScrollTopPosition = newValue;
      }
    },

    beforeDestroy () {
      this.EventBus.$off('updatePortfolio');
    },

    created() {
      this.username = this.$store.state.routerParams[0];
      this.retrievePortfolios(this.offset, this.limit);

      this.EventBus.$on('updatePortfolio', payload => {

        let uuid = payload.uuid;
        let what = payload.what;
        let type = payload.type;

        if(what==='comment' || what==='fav_count'){
          this.portfolios.forEach(function (portfolio) {
            if (portfolio.uuid === uuid) {
              if(what==='comment'){
                if(type === 'plus') portfolio.comment_count += 1;
                else  portfolio.comment_count -= 1;
              }


              if(what==='fav_count'){
                if(type === 'plus') portfolio.fav_count += 1;
                else  portfolio.fav_count -= 1;
              }

            }
          });
        }

        if(what==='delete'){
          this.portfolios = this.portfolios.filter(function (portfolio) {
            return portfolio.uuid !== uuid;
          });
        }

        if(what==='update'){
          this.portfolios = this.portfolios.filter((portfolio) => {
            return portfolio.status === this.type;
          });
        }

      });


    }

  }
</script>

<style scoped lang="scss">


  .tags{
    margin-bottom: 30px;
    display: flex;
    .tag{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      height: 32px;
      padding-bottom: 2px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 4px;
      border: solid 1px #8b95a1;
      font-size: 13px;
      font-weight: normal;
      color: #8b95a1;
      &:hover{
        border: solid 1px #26ca9a;
        color: #26ca9a;
      }
    }

    svg{

      width: 10px;
      height: 10px;
    }

    .arrow-icon /deep/{
      margin-left: 10px;
      margin-top:5px;
      width: 10px;
      height: 10px;
      path {
        fill: #8b95a1;
      }
    }

    .tag-selected{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      height: 32px;
      border-radius: 4px;
      background-color: #26ca9a;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 2px;
      border: solid 1px #26ca9a;
      font-size: 13px;
      font-weight: normal;
      color: #ffffff;


    }
  }

  .card-container {
    display: flex;
    flex-direction: column;


    margin-left: 26px;
    margin-bottom: 30px;
    width: 277px;
    height: 274px;

    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    &:nth-child(4n+1) {
      margin-left: 0;
    }
  }

  .card-image-container {
    height: 148px;
    background-color: #fafbfc;
    border-bottom: solid 1px #dfe4ec;
  }

  .card-detail-container {
    padding: 15px;
  }

  .card-detail-header-large {
    width: 234px;
    height: 16px;
    background-color: #e1e6ed;
    margin-bottom: 4px;
  }

  .card-detail-header-small {
    width: 117px;
    height: 16px;
    background-color: #e1e6ed;
  }

  .card-detail-user-image {
    width: 40px;
    height: 40px;
    background-color: #dfe4ec;
    border-radius: 50%;
  }

  .card-detail-user-name {
    width: 150px;
    height: 12px;
    margin-left: 10px;
    background-color: #e1e6ed;

  }

  .card-detail-currency {
    width: 63px;
    height: 16px;
    margin-left: 10px;
    background-color: #e1e6ed;
  }

  .card-detail-user-container {
    display: flex;
    align-items: center;
    margin: 2px 15px 0px;
  }

  .card-detail-user {
    height: 66px;
    display: flex;
    align-items: center;
  }

  .card-like-icon {
    width: 55px;
    height: 24px;
    background-color: #e1e6ed;
  }

  .card-currency-icon {
    width: 42px;
    height: 20px;
    background-color: #bdc5d0;
  }

  .card-like-currency {
    display: flex;
    align-items: center;
    margin: 13px 15px;
  }

  .card {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .service-label {
    padding-left: 10px;
  }

  @keyframes loading {
    10% {
      background-color: #dfe4ec;
    }
    2% {
      background-color: #fafbfc;
    }
  }

  .portfolio-body{
    display: flex;
    flex-wrap: wrap;

    .portfolio-box{
      margin-left: 24px;
      margin-bottom: 30px;
      width: 280px;
      height: 274px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;

      .head-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
        height: 66px;
        .title{
          font-size: 14px;
          font-weight: 600;
          color: #2d3640;
        }
        .sub-title{
          margin-top: 5px;
          font-size: 12px;
          font-weight: 300;
          color: #8b95a1;
          a{
            font-size: 12px;
            font-weight: 600;
            color: #fd4056;
            &:hover{
              text-decoration: underline;
              color: #fd4056!important;
            }
          }
        }
      }

      .portfolio-img{
        display: block;

        position: relative;
        cursor: pointer;
        height: 158px;
       img{
         width: 100%;
       }
      }

      .foot-section{

        display: flex;
        align-items: center;
        margin-left: 20px;
        height: 48px;

        font-size: 16px;
        font-weight: 600;
        color: #8b95a1;

        span{
          padding-left: 5px;
          padding-right: 15px;

        }
        .like-icon /deep/ {
          cursor: pointer;
          width: 16px;
          height: 16px;
          path {
            fill: #bfc8d2!important;
          }
        }

      }
    }
  }


  .user-portfolio{
    width: 1195px;
    margin: 0 auto 100px;
  }


  .user-portfolio-header{

    margin-top: 36px;
    margin-bottom: 67px;
    display: flex;


    .avatar{
      height: 98px;
      width: 98px;
      border:2px solid #fd4056;
      border-radius: 50%;
    }

    .title{
      font-size: 34px;
      font-weight: 600;
      color: #2d3640;
      margin: 0;
      span{
        font-size: 34px;
        font-weight: normal;
        color: #6a7685;
      }
    }
    .sub-title{
      margin-top: 9px;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.56;
      color: #6a7685;
      max-width: 693px;
      a{
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        color: #fd4056;
        &:hover{
          text-decoration: underline;
          color: #fd4056;
        }
      }
    }


    .share-area{

      display: flex;


      .user-socialmedia-icons {
        display: flex;
        flex-direction: row;
        height: 26px;
      }

      .text{
        margin-right: 20px;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.33;
        color: #8b95a1;
      }


    }
  }


  .see-more-div {
    margin:30px auto 60px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
  }

  .see-more-div-loader {
    width: 25px;
    height: 25px;
    margin:30px auto 60px;
  }


  .seslendirme-filter-item{
    cursor: pointer;
    margin-left: 22px;  width: 130px;
    height: 48px;
    border-radius: 5px;
    border: solid 1px #bfc8d2;
    background-color: #ffffff; color:#5e6b79; font-size: 14px; font-weight: normal; display: flex; align-items: center; padding-left:20px; padding-right: 15px; justify-content: space-between;

    &:hover{
      border:solid 1px #00a575;
    }
    svg{

      width: 10px;
      height: 10px;
    }

    .arrow-icon /deep/{
      margin-left: 10px;
      margin-top:5px;
      width: 10px;
      height: 10px;
      path {
        fill: #8b95a1;
      }
    }
  }


  .seslendirme-filter-item-selected{
    cursor: pointer;
    margin-left: 22px;  width: 130px;
    height: 48px;
    border-radius: 5px;
    border: solid 1px #2d3640;
    background-color: #ffffff; color:#2d3640; font-size: 14px; font-weight: normal; display: flex; align-items: center; padding-left:20px; padding-right: 15px; justify-content: space-between;

    &:hover{
      border:solid 1px #00a575;
    }
    svg{

      width: 10px;
      height: 10px;
    }

    .arrow-icon /deep/{
      margin-left: 10px;
      margin-top:5px;
      width: 10px;
      height: 10px;
      path {
        fill: #2d3640;
      }
    }
  }


  .seslendirme-filter-item-active{
    cursor: pointer;
    margin-left: 22px;  width: 130px;
    height: 48px;
    border-radius: 5px;
    border: solid 1px #00a575;
    background-color: #ffffff; color:#2d3640; font-size: 14px; font-weight: normal; display: flex; align-items: center; padding-left:20px; padding-right:15px; justify-content: space-between;


    svg{

      width: 10px;
      height: 10px;
    }

    .arrow-icon /deep/{
      margin-left: 10px;
      margin-top:5px;
      width: 10px;
      height: 10px;
      path {
        fill: #2d3640;
      }
    }
  }


  .ses-gender-container{
    outline: 0;
    position: absolute;
    width: 280px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(45, 54, 64, 0.15);
    border: solid 1px #eff3f8;
    background-color: #ffffff;  z-index: 99;


    .ses-gender-radio-item{
      display: flex; align-items: center; cursor: pointer;

      .ses-gender-radio-empty{
        width: 19px;
        height: 19px;
        border-radius: 50%;
        border: solid 1px #8b95a1;
      }

      .ses-gender-radio-label{
        font-size: 14px; color: #5e6b79; margin-left: 5px; line-height: 1.4
      }

      .ses-gender-radio-label-active{
        font-size: 14px; color: #2d3640; margin-left: 5px; line-height: 1.4
      }

      .ses-gender-radio-full{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: solid 4px #00a575;
      }

      &:hover{
        .ses-gender-radio-label{
          font-size: 14px; color: #2d3640; margin-left: 5px; line-height: 1.4
        }

        .ses-gender-radio-empty{
          width: 19px;
          height: 19px;
          border-radius: 50%;
          border: solid 1px #00a575;
        }
      }


    }
  }
</style>
